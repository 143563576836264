// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/truthist-secondary-icon-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/truthist-secondary-icon-button.tsx");
  import.meta.hot.lastModified = "1733459677540.6008";
}
// REMIX HMR END

import { IconButton, useColorModeValue } from "@chakra-ui/react";
export default function TruthistSecondaryIconButton({
  children,
  label,
  ...rest
}) {
  _s();
  const hoverColor = useColorModeValue("gray.400", "gray.600");
  return <IconButton size={"sm"} justifyItems={"center"} border={0} borderRadius={"md"} backgroundColor={useColorModeValue("white", "black")} aria-label={label} icon={children} type="submit" _hover={{
    bgColor: hoverColor
  }} {...rest} />;
}
_s(TruthistSecondaryIconButton, "xDlk5S8/pNtDjwli6/3KYnrzemQ=", false, function () {
  return [useColorModeValue, useColorModeValue];
});
_c = TruthistSecondaryIconButton;
var _c;
$RefreshReg$(_c, "TruthistSecondaryIconButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;