// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/truthist-header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/truthist-header.tsx");
  import.meta.hot.lastModified = "1733459677540.6008";
}
// REMIX HMR END

import { Avatar, Box, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import { FaRegMessage, FaRegUser } from "react-icons/fa6";
import TruthistPrimaryIconButton from "./truthist-primary-icon-button";
import TruthistSecondaryIconButton from "./truthist-secondary-icon-button";
export default function TruthistHeader({
  character
}) {
  _s();
  const {
    colorMode,
    toggleColorMode
  } = useColorMode();
  const hoverColor = useColorModeValue("gray.300", "gray.500");
  return <Flex h={12} pt={2} px={4} mb={8} w="full" justifyContent={"space-between"}>
      <HStack gap={2}>
        {character && <TruthistSecondaryIconButton as={Box} my={"auto"}>
            <Menu>
              <MenuButton transition="all 0.3s" _focus={{
            boxShadow: "none"
          }}>
                <FaRegUser size={20} />
              </MenuButton>
              <MenuList>
                <MenuItem as={Link} _hover={{
              bg: hoverColor
            }} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem as={Link} _hover={{
              bg: hoverColor
            }} to="/library">
                  Library
                </MenuItem>

                <MenuItem as={Link} _hover={{
              bg: hoverColor
            }} onClick={toggleColorMode} aria-label={"color mode"}>
                  {colorMode === "light" ? "Switch to Black Board" : "Switch to White Board"}
                </MenuItem>
                <MenuItem as={Link} _hover={{
              bg: hoverColor
            }} to="/logout">
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </TruthistSecondaryIconButton>}
      </HStack>

      <TruthistPrimaryIconButton onClick={toggleColorMode} my={"auto"}>
        <Avatar src={useColorModeValue("/assets/logo/truthist-logo.png", "/assets/logo/truthist-logo-dark.png")} size="sm" name="Truthist" background={"transparent"} />
      </TruthistPrimaryIconButton>

      <HStack>
        {character && <TruthistSecondaryIconButton as={Link} to="/" my={"auto"}>
            <FaRegMessage size={20} />
          </TruthistSecondaryIconButton>}
      </HStack>
    </Flex>;
}
_s(TruthistHeader, "0+reH5S0Zdqe9mWUWBW5syB9eE0=", false, function () {
  return [useColorMode, useColorModeValue, useColorModeValue];
});
_c = TruthistHeader;
var _c;
$RefreshReg$(_c, "TruthistHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;